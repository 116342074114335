import axios, { CancelToken, CancelTokenSource } from 'axios';
import Axios from 'axios';
import { MarketingOption } from 'components/marketing-toggle/MarketingToggle';

export type LicenceType = 'CARD' | 'DIGITAL';

export type MarketingPreferences = {
  receiveGeneralMarketing: MarketingOption | null;
  receiveGamingMaterial: MarketingOption | null;
  receiveClubsAnnualReport: MarketingOption | null;
  autoRenewMembershipWithPoints: boolean | null;
};

class ApiClient {
  apiBaseUrl!: string;
  venueKey!: string;
  venueId!: number;

  init(apiBaseUrl: string) {
    this.apiBaseUrl = apiBaseUrl;
    // this.venueKey = venueKey;

    // Catch all 401/403 and force a user logout
    Axios.interceptors.response.use(
      (response) => {
        // Do something with response data
        return response;
      },
      (error) => {
        // if (401 === error.response.status || 403 === error.response.status) {
        //     console.log('FOUND 401 STATUS')
        //     // userState.logout();
        // }
        return Promise.reject(error);
      }
    );

    Axios.defaults.withCredentials = true;
  }

  async getVenueTheme() {
    /*
        {
            "venueKey": "pe".
            "displayName": "Player Elite",
            "homePageRichHtml": null,
            "logoImgUrl": "https://s3-ap-southeast-2.amazonaws.com/pe-venue-landing-assets-prod/petest/petest_app_logo.png"
        }
        */
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueKey}/venue-theme`;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {})
        .then((response) => {
          if (!response.data.success) {
            reject(response);
            return;
          }
          this.venueId = response.data.body.venueId;
          resolve(response.data.body);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  async startWithLicense(licenceS3Key: string, licenceType: LicenceType) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/signup/license-start`;
    return axios
      .post(url, { licenceS3Key, licenceType }, {})
      .catch((error: any) => {});
  }

  async verifySelfie(selfieS3Key: string, flowToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/signup/validate-selfie`;
    return axios
      .post(url, { flowToken, selfieS3Key }, {})
      .catch((error: any) => {});
  }

  async getSignedUploadPresignedS3Url(fileName: string) {
    const url = `${this.apiBaseUrl}/v1/public/presigned-upload-url`;
    return axios.post(url, { fileName }, {}).catch((error: any) => {});
  }

  async uploadToS3PresignedUrl(
    url: string,
    data: any,
    progressCallback: (percentCompleted: number) => void
  ) {
    let config = {
      onUploadProgress: (progressEvent: any) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        progressCallback(percentCompleted);

        console.log('percent: ' + percentCompleted);
      },
      withCredentials: false,
      headers: {
        'Content-Type': 'image/*',
      },
    };

    return axios.put(url, data, config);
  }

  async isUserMember(nextFlowGuid: string, flowToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/processing/is-user-member`;
    return axios
      .post(url, { nextFlowGuid, flowToken }, {})
      .catch((error: any) => {});
  }

  async submitAdditionalInfo(
    data: {
      nameTitle: string;
      gender: string;
      homeAddress: string;
      emailAddress: string;
      phoneNumber: string;
    },
    flowToken: string
  ) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/signup/additional-info`;
    return axios
      .post(url, { ...data, flowToken }, {})
      .catch((error: any) => {});
  }

  async getOccupations(searchTerm: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/signup/occupation/${searchTerm}`;
    return axios.get(url, {}).catch((error: any) => {});
    /*
        {
          "success": true,
          "occupations": [
            {
              "hydraWageJobCodesID": 43,
              "hydraWageJobCodeATO": "133211",
              "hydraWageJobNameATO": "Engineering Manager"
            },
            {
              "hydraWageJobCodesID": 202,
              "hydraWageJobCodeATO": "231212",
              "hydraWageJobNameATO": "Ship's Engineer"
            },
            {
              "hydraWageJobCodesID": 221,
              "hydraWageJobCodeATO": "233111",
              "hydraWageJobNameATO": "Chemical Engineer"
            }
        }
        */
  }

  async saveOccupation(hydraWageJobCodeATO: string, flowToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/signup/save-occupation`;
    return axios
      .post(url, { hydraWageJobCodeATO, flowToken }, {})
      .catch((error: any) => {});
  }

  async setPensioner(pensioner: boolean, flowToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/signup/set-pensioner`;
    return axios
      .post(url, { pensioner, flowToken }, {})
      .catch((error: any) => {});
  }

  async submitSignature(signatureS3Key: string, flowToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/signup/save-signature`;
    return axios
      .post(url, { signatureS3Key, flowToken }, {})
      .catch((error: any) => {});
  }

  async saveMarketing(pref: MarketingPreferences, flowToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/signup/save-marketing`;
    return axios
      .post(url, { ...pref, flowToken }, {})
      .catch((error: any) => {});
  }

  async checkoutStripe(venueMembershipProductId: number, flowToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/signup/stripe-checkout-session`;
    return axios
      .post(url, { venueMembershipProductId, flowToken }, {})
      .catch((error: any) => {});
  }

  async checkoutCash(venueMembershipProductId: number, flowToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/signup/cash-checkout-session`;
    return axios
      .post(url, { venueMembershipProductId, flowToken }, {})
      .catch((error: any) => {});
  }

  async checkoutFree(venueMembershipProductId: number, flowToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/signup/free-checkout-session`;
    return axios
      .post(url, { venueMembershipProductId, flowToken }, {})
      .catch((error: any) => {});
  }

  async getMembership(nextFlowGuid: string, flowToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/processing/get-membership`;
    return axios
      .post(url, { nextFlowGuid, flowToken }, {})
      .catch((error: any) => {});
  }
  async restoreSession(restoreToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueKey}/signup/restore`;
    return axios.post(url, { restoreToken }, {}).catch((error: any) => {});
  }

  async expireSession(restoreToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueKey}/signup/restore/expire`;
    return axios.post(url, { restoreToken }, {}).catch((error: any) => {});
  }

  async vaidateRestoreOTP(restoreToken: string, smsCode: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueKey}/signup/restore-code`;
    return axios
      .post(url, { restoreToken, smsCode }, {})
      .catch((error: any) => {});
  }

  async getAppleWalletUrl(nextFlowGuid: string, flowToken: string) {
    // const url = `https://hydra-api-dev.playerelite-dev.com/api/v1/card/apple`;
    // return axios.get(url, {responseType: 'arraybuffer'}).catch((error: any) => {});

    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/card/apple`;
    return axios
      .get(url, { params: { nextFlowGuid, flowToken } })
      .catch((error: any) => {});
    // return axios.post(url, { nextFlowGuid, flowToken },
    //   {
    //       headers: {['X-HTTP-Method-Override']: 'GET'},
    //       params: {_method: 'GET'}
    //   }).catch((error: any) => {});
    // return axios({
    //     method: 'get',
    //     url: url,
    //     data: { nextFlowGuid, flowToken }
    // }).catch((error: any) => {});

    // const xmlHttpReq = new XMLHttpRequest();
    // xmlHttpReq.setRequestHeader("Content-Type", "application/json");
    // xmlHttpReq.setRequestHeader("X-HTTP-Method-Override", "GET");
    // xmlHttpReq.open("POST", url);
    // await xmlHttpReq.send(JSON.stringify({ nextFlowGuid, flowToken }));
    //
    // xmlHttpReq.onreadystatechange = (e) => {
    //     console.log(xmlHttpReq.responseText)
    // }
  }

  async getGoogleWalletUrl(nextFlowGuid: string, flowToken: string) {
    const url = `${this.apiBaseUrl}/v1/venue/${this.venueId}/card/google`;
    return axios
      .post(url, { nextFlowGuid, flowToken }, {})
      .catch((error: any) => {});
  }

  sendDownloadSms(mobileNumber: string) {
    return axios.post(
      `${this.apiBaseUrl}/v1/venue/${this.venueId}/card/send-download-sms`,
      {
        mobileNumber,
      }
    );
  }

  async downloadCard(phoneOs: "APPLE" | "GOOGLE", token: string) {
    return axios.post(
      `${this.apiBaseUrl}/v1/venue/${this.venueKey}/dc/download`,
      {
        phoneOs,
        token,
      },
      {responseType: phoneOs === "APPLE" ? 'arraybuffer' : undefined}
    )
  }
}

export default new ApiClient();
