import styled from "@emotion/styled";
import React, {useEffect, useMemo, useRef, useState} from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import 'react-phone-number-input/style.css'
import {FormSection} from "pages/signup/FormSegments";
import {useMembershipApplication} from "context/ApplicationContext";
import {useNavigate} from "react-router-dom";
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import Header from "components/header/Header";
import useBeforeUnload from "util/useBeforeUnload";

type IndexedStepProps = {
	accomplished?: boolean
}
const IndexedStep = styled.div<IndexedStepProps>`
  color: white;
  // width: 20px;
  // height: 20px;
  // font-size: 12px;

  ${props => (props.accomplished ? `
		background: linear-gradient(45deg, rgba(178,144,68,1) 0%, rgba(199,185,82,1) 100%);
		color: black;
	` : `
		background: linear-gradient(135deg, rgba(212,212,207,1) 0%, rgba(250,250,240,1) 50%, rgba(217,217, 206,1) 100%);
		color: rgba(0,0,0,0);
	`)};
	
	
  width: 30px;
  height: 30px;
  font-size: 16px;
	font-family: Montserrat, system-ui;
	font-weight: 500;
  border-radius: 50%;
  display: flex;
  /* opacity: ${props => (props.accomplished ? '1' : '0')}; */
  justify-content: center;
  align-items: center;
`;

type TitleTextProps = {
	short?: boolean
}
const TitleText = styled.div<TitleTextProps>`
	//max-width: 50%;
  //margin-bottom: 0.5rem;
  margin: 0.7em 0 1.7em 0;
  display: block;
	font-family: Montserrat, system-ui;
  ${props => (props.short ? 'font-size: 2rem;' : 'font-size: 1.7rem; line-height: 2em;')};
  font-weight: 800;
  //background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);
  background: linear-gradient(90deg, rgba(178,144,68,1) 0%, rgba(199,185,82,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  //letter-spacing: 0.05em;
`;

const SignUpHeader = styled.div`
	text-align: center;
  background: radial-gradient(rgba(34,33,34,1) 10%, rgba(10,8,11,1) 100%);
	padding-bottom: 2em;
`;

const SecurityFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
	height: 2rem;
  //background: red;
	color: darkslategray;
  //font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
	gap: 0.3rem;
  font-size: 0.9rem;
`;


const FormArea = styled.div`
	position: relative;
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
	flex-grow: 1;
  background: linear-gradient(135deg, rgba(207,207,202,1) 0%, rgba(248,247,246,1) 40%, rgba(210,210,198,1) 100%);
	border-radius: 3em 0 0 0;
  margin-top: -3em;
`;

function anyKeyTrue(obj: {[key: string]: boolean}) {
	for (const key in obj) {
		if (obj.hasOwnProperty(key) && obj[key]) return true;
	}
	return false;
}

export const sampleTermsHtml2 = `
	<h4>CONSENT</h4>
	<p>In the interest of preserving the environment and saving paper, I consent to accessing the Financial Report on the Club’s website or by requesting one at the Club’s reception. I acknowledge that under the Club’s constitution the Notice of Annual General Meeting will be sent to me electronically. I also content to receive exciting offers as well as information relating to the Hornsby Rewards Program and promotions (this may include gaming promotions) via electronic methods. I also acknowledge that if I wish to update these preferences I must do so in writing to the email found at the bottom of this application.</p>
	<h4>PRIVACY STATEMENT</h4>
	<p>The Club collects, stores, uses and discloses personal information strictly in accordance with the Privacy Act 1988. If you would like to see our Privacy Policy it can be obtained on our website.</p>
	<h4>DECLARATION</h4>
	<p>I request that you enter my name on the Register of Members as Member and agree to be bound by the Hornsby RSL Club Constitution, Rules and By-Laws (available on the Club’s website) which may be updated from time to time. I declare that I am over the age of 18 years and that all of the above details are true and correct. Proof of age/verification may be called upon at the discretion of Club management.</p>
`;
export const sampleTermsHtml = `
	<h4>DECLARATION AND CONSENT</h4>
	<ol>
		<li>I declare that the information I have provided in this application is true</li>
		<li>I acknowledge my application is subject to the approval of the Board or the Election Committee</li>
		<li>I consent to the collection, use and disclosure of my personal information in accordance with the Club's privacy policy (as amended) which is available at www.chprsl.com.au or on request to the Club.</li>
		<li>I am aware that I can change my choices about receiving promotional material, including gaming promotional material, by notifying the Club.</li>
		<li>I acknowledge that Full Members can participate in the Insignia Member Rewards Program. Swiping my membership card at any gaming machine, point of sale or other membership terminal activates my participation and I consent to the program terms and conditions, which are available from Club Reception (as amended). If I do not wish to participate then I will not swipe my card. I am aware that player activity statements are available on request in accordance with the applicable law and I can also choose to opt out of the program by notifying the Club.</li>
		<li>I understand that I can choose to receive the annual financial report, Directors' report and auditor's report by post or email, by giving the Club written notice.</li>
		<li>I will comply with the Club's Constitution, by-laws and rules (as amended). I have read the above declaration disclosure.</li>
	</ol>
`;


export const SignUpPage = () => {
	let navigate = useNavigate();
	const { appState, userInfo, setUserInfo } = useMembershipApplication()
	const [progressStep, setProgressStep] = useState(0)

	const [blockBack, setBlockBack] = useState(true);
	useBeforeUnload(blockBack);

	// const showMarketingPage= true;
	const showMarketingPage = (appState.theme?.marketingShowGeneral || appState.theme?.marketingShowGaming || appState.theme?.marketingShowAnnualReport || appState.theme?.showAutoRenew) ?? false;
	const showAdditionalInfoPage= !!appState.theme?.showOccupation // || (!!appState.theme?.showPensioner && !!userInfo.isPensionEligible)
	const numSteps = 4 + (showAdditionalInfoPage ? 1 : 0) + (showMarketingPage ? 1 : 0);

	useEffect(() => {
		// console.log(progressStep)

		// ON LOAD, IF WE DON'T HAVE TOKENS - ALERT AND BRING BACK TO LANDING PAGE
		if (!appState.flowToken || !appState.nextFlowGuid) {
		  setBlockBack(false);
			setTimeout(()=> {
				window.alert('Your session has expired, please restart the application.');
				navigate(`/${appState.theme?.venueKey ?? ""}`, { replace: true });
			}, 50);
    }
	}, [])

  return (
    <>
      <SignUpHeader>
        <Header showShadow />
	      { userInfo.currentMemberInfo?.isUserAMember ? (
		      <TitleText><small>Renew Membership</small></TitleText>
	      ) : (
		      <TitleText short>Sign up</TitleText>
	      )}
      </SignUpHeader>
      <FormArea>
        <div style={{width: '70%', padding: '20px 15%', position: 'absolute', top: -25}}>
	        <ProgressBar percent={progressStep * 100/(numSteps - 1)} filledBackground='linear-gradient(90deg, rgba(178,144,68,1) 0%, rgba(199,185,82,1) 100%)' unfilledBackground='white' height={6}>
		        {Array.from({length: numSteps}, (_, index) => (
			        <Step key={index} transition="scale">
				        {({ accomplished, index }) => (
					        <IndexedStep accomplished={accomplished} onClick={() => {
						        // todo: check if we've filled the data instead of only allowing to go backwards
						        // if (accomplished)
										// 	setProgressStep(index);
					        }}>
						        {index + 1}
					        </IndexedStep>
				        )}
			        </Step>
		        ))}
	        </ProgressBar>
        </div>
        <FormSection showAdditionalInfoPage={showAdditionalInfoPage} showMarketingPage={showMarketingPage} stage={progressStep} onNext={(done) => {
	        // const isLastStep = progressStep === numSteps - 1
	        // if (isLastStep) {
	        if (done) {
		        setBlockBack(false);
		        // completedSignup()
	        } else {
		        setProgressStep(progressStep + 1)
	        }
        }} />
        <SecurityFooter>
	        <VerifiedUserRoundedIcon fontSize={'inherit'}/>Your data is secure
        </SecurityFooter>
      </FormArea>
    </>
  )
}
